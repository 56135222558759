import React from "react";
import { Outlet } from "react-router-dom";
import { Wrapper, Content, Container } from "./index.style";
import Footer from "../../components/footer";
import Header from "../../components/header";

const UserLayout = () => {
  return (
    <Wrapper>
      <Header />
      <Content>
        <Container>
          <Outlet />
        </Container>
      </Content>
      <Footer />
    </Wrapper>
  );
};

export default UserLayout;
