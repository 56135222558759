import userState from "../state/userState";
const userReducer = (state = userState, action) => {
  switch (action.type) {
    case "UPDATE_USER_INFO":
      return {
        ...state,
        ...action.payload,
      };
    case "CLEAR_USER_INFO":
      return {
        ...state,
        username: "",
        phone: "",
      };
    default:
      return state;
  }
};

export default userReducer;
