import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    background: linear-gradient(#ffffff, #ffffff, #c3dff5);
    border: 2px solid #005eb8;
    border-radius: 10px;
    .ant-modal-body {
      border-radius: 10px;
      background: linear-gradient(#ffffff, #ffffff, #c3dff5);
      padding: 1em 2.5em;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
      .ant-input-number,
      .ant-form-item {
        border-radius: 10px;
        width: 100%;
        input {
          border: 2px solid #353750;
          height: 40px;
          border-radius: 10px;
          overflow: hidden;
        }
      }
      .ant-input-number {
        .ant-input-number-handler-wrap {
          display: none;
        }
      }
    }
  }

  .ant-modal-header {
    display: none;
  }

  @media (max-width: 767px) {
    & {
      max-width: calc(100vw - 59px);
      margin: 8px auto;
      .ant-modal-body {
        padding: 1em 1.5em !important;
      }
    }
  }
`;
export const PhotoArea = styled("div")`
  max-width: 312px;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  img {
    width: 100%;
  }
  @media (max-width: 390px) {
    & {
      img {
        width: 85%;
      }
    }
  }
`;
export const UserName = styled("h3")`
  text-align: center;
  margin-top: 0.4em;
  margin-bottom: 0em;
  font-weight: 900;
  font-size: 1.7em;
  color: #353750;
`;
export const Items = styled("p")`
  margin-bottom: 0.5em;
  font-size: 1.5em;
  color: #353750;
  text-align: center;
  font-weight: 600;
`;
export const PlayButton = styled(Button)`
  background: #dc1f2c;
  font-weight: 900;
  display: inline-block;
  width: 135px;
  height: 45px;
  border: 1px solid #dc1f2c;
  margin: 0px auto;
  font-size: 1.5em;
  border-radius: 10px;
  font-weight: 700;
  color: #ffffff;
`;
