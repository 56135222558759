import React, { useEffect, useState } from "react";
import { QuizCard, Wrapper } from "../../common/index.style";
import { AboutText, Ans, QuizNum, QuizOption, TopHeader } from "./index.style";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { clearQuiz, toggleQuestion } from "actions/quizActions";
import { useAppContext } from "context/GlobalContext";

const Quiz = () => {
  const { quizState, quizDispatch, setScore, userState } = useAppContext();
  const navigate = useNavigate();
  const [correctAns, setCorrectAns] = useState(false);
  const [secondQuiz, setSecondQuiz] = useState(false);
  const [thirdQuiz, setThirdQuiz] = useState(false);
  const [fourthQuiz, setFourthQuiz] = useState(false);
  const [wrongAnswers, setWrongAnswers] = useState({});
  // Clear previous quiz status

  useEffect(() => {
    if (!userState?.username) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    quizDispatch(clearQuiz());
    setScore(null);
  }, []);

  const updateQuizStates = (quiz) => {
    switch (quiz) {
      case "quizOne":
        setTimeout(() => {
          setSecondQuiz(true);
        }, 500);
        break;
      case "quizTwo":
        setTimeout(() => {
          setThirdQuiz(true);
        }, 500);
        break;
      case "quizThree":
        setTimeout(() => {
          setFourthQuiz(true);
        }, 500);
        break;
      case "quizFour":
        setTimeout(() => {
          navigate("/result");
        }, 500);
        break;
    }
  };

  const handleQuiz = (quiz) => {
    // Just toggele when user not select wrong question
    if (!wrongAnswers?.[quiz]) {
      quizDispatch(toggleQuestion(quiz));
    }
    updateQuizStates(quiz);
  };

  const handleWrongAnswer = (quizNumber) => (currentQuiz) => {
    setWrongAnswers((prev) => ({
      ...prev,
      [quizNumber]: { [currentQuiz]: true },
    }));
    updateQuizStates(quizNumber);
  };

  console.log(wrongAnswers, " wrongAnswers...");
  console.log(wrongAnswers?.quizOne?.["10%"] ? "wrongAns" : "");

  const quizOne = handleWrongAnswer("quizOne");
  const quizTwo = handleWrongAnswer("quizTwo");
  const quizThree = handleWrongAnswer("quizThree");
  const quizFour = handleWrongAnswer("quizFour");

  // console.log(quizState, "quizState...");

  return (
    <Wrapper>
      <QuizCard>
        <TopHeader>
          <h1>सवाल / QUESTION</h1>{" "}
        </TopHeader>
        {fourthQuiz ? (
          <>
            {/* Quiz 4 */}
            <QuizNum>
              <h2>4</h2>
              <img src="/images/number.png" alt="" />
            </QuizNum>
            <AboutText>
              आप कैसे Netafim से संपर्क कर सकते हैं ताकि आप तूफ़ान ड्रिपलाइन के
              लाभ प्राप्त कर सकें?
            </AboutText>
            <AboutText>
              How can you contact Netafim to get the benefits of Toofan Dripline
            </AboutText>
            <QuizOption>
              <Row>
                <Col
                  span={24}
                  onClick={() =>
                    quizFour("हमारी वेबसाइट www.toofandripline.com पर जाएं")
                  }
                >
                  <Ans
                    style={{ fontSize: ".8em" }}
                    className={
                      wrongAnswers?.quizFour?.[
                        "हमारी वेबसाइट www.toofandripline.com पर जाएं"
                      ]
                        ? "wrongAns"
                        : ""
                    }
                  >
                    हमारी वेबसाइट www.toofandripline.com पर जाएं <br /> Visit us
                    at www.toofandripline.com
                  </Ans>
                </Col>
                <Col
                  span={24}
                  onClick={() =>
                    quizFour("टोल फ्री नंबर 1800 103 2000 पर कॉल करें")
                  }
                >
                  <Ans
                    className={
                      wrongAnswers?.quizFour?.[
                        "टोल फ्री नंबर 1800 103 2000 पर कॉल करें"
                      ]
                        ? "wrongAns"
                        : ""
                    }
                  >
                    टोल फ्री नंबर 1800 103 2000 पर कॉल करें <br /> Toll free
                    number 1800 103 2000
                  </Ans>
                </Col>
                <Col
                  span={24}
                  onClick={() => quizFour("Netafim डीलर की दुकान पर जाएं")}
                >
                  <Ans
                    className={
                      wrongAnswers?.quizFour?.["Netafim डीलर की दुकान पर जाएं"]
                        ? "wrongAns"
                        : ""
                    }
                  >
                    Netafim डीलर की दुकान पर जाएं <br /> Visit Netafim Dealer
                    shop
                  </Ans>
                </Col>
                <Col span={24}>
                  <Ans
                    className={
                      wrongAnswers?.quizFour || quizState?.quizFour
                        ? "rightAns"
                        : "bgGrayGradiant"
                    }
                    onClick={() => {
                      handleQuiz("quizFour");
                    }}
                  >
                    उपर्युक्त सभी विकल्प <br /> All of the above
                  </Ans>
                </Col>
              </Row>
            </QuizOption>
          </>
        ) : (
          <>
            {" "}
            {thirdQuiz ? (
              <>
                {/* Quiz 3 */}
                <QuizNum>
                  <h2>3</h2>
                  <img src="/images/number.png" alt="" />
                </QuizNum>
                <AboutText>
                  तूफ़ान ड्रिपलाइन किस मौसम में बढ़िया चलता है?
                </AboutText>
                <AboutText>
                  In which season is the Toofan Dripline most effective?
                </AboutText>
                <QuizOption>
                  <Row>
                    <Col
                      span={24}
                      onClick={() => quizThree("गर्मियों में / During summers")}
                    >
                      <Ans
                        className={
                          wrongAnswers?.quizThree?.[
                            "गर्मियों में / During summers"
                          ]
                            ? "wrongAns"
                            : ""
                        }
                      >
                        गर्मियों में / During summers
                      </Ans>
                    </Col>
                    <Col
                      span={24}
                      onClick={() => quizThree("सर्दियों में / During winters")}
                    >
                      <Ans
                        className={
                          wrongAnswers?.quizThree?.[
                            "सर्दियों में / During winters"
                          ]
                            ? "wrongAns"
                            : ""
                        }
                      >
                        सर्दियों में / During winters
                      </Ans>
                    </Col>
                    <Col
                      span={24}
                      onClick={() =>
                        quizThree("मॉनसून में / During the monso on")
                      }
                    >
                      <Ans
                        className={
                          wrongAnswers?.quizThree?.[
                            "मॉनसून में / During the monso on"
                          ]
                            ? "wrongAns"
                            : ""
                        }
                      >
                        मॉनसून में / During the monsoon
                      </Ans>
                    </Col>
                    <Col span={24}>
                      <Ans
                        className={
                          wrongAnswers?.quizThree || quizState?.quizThree
                            ? "rightAns"
                            : "bgGrayGradiant"
                        }
                        onClick={() => {
                          handleQuiz("quizThree");
                        }}
                      >
                        हर मौसम, हर फसल के लिए / <br /> All year round, for
                        every crop
                      </Ans>
                    </Col>
                  </Row>
                </QuizOption>
              </>
            ) : (
              <>
                {secondQuiz ? (
                  <>
                    {/* Quiz 2 */}
                    <QuizNum>
                      <h2>2</h2>
                      <img src="/images/number.png" alt="" />
                    </QuizNum>
                    <AboutText>
                      तूफ़ान aam dripline se adhik _____ hai?
                    </AboutText>
                    <AboutText>
                      Toofan is more _____ than the ordinary Dripline.
                    </AboutText>
                    <QuizOption>
                      <Row>
                        <Col span={24}>
                          <Ans
                            className={
                              wrongAnswers?.quizTwo || quizState?.quizTwo
                                ? "rightAns"
                                : "bgGrayGradiant"
                            }
                            onClick={() => {
                              handleQuiz("quizTwo");
                            }}
                          >
                            किफायती / Cost-effective
                          </Ans>
                        </Col>
                        <Col
                          span={24}
                          onClick={() => quizTwo("सुंदर / Beautiful")}
                        >
                          <Ans
                            className={
                              wrongAnswers?.quizTwo?.["सुंदर / Beautiful"]
                                ? "wrongAns"
                                : ""
                            }
                          >
                            सुंदर / Beautiful
                          </Ans>
                        </Col>
                        <Col
                          span={24}
                          onClick={() => quizTwo("प्यारा / Lovely")}
                        >
                          <Ans
                            className={
                              wrongAnswers?.quizTwo?.["प्यारा / Lovely"]
                                ? "wrongAns"
                                : ""
                            }
                          >
                            प्यारा / Lovely
                          </Ans>
                        </Col>
                        <Col
                          span={24}
                          onClick={() => quizTwo("खतरनाक / Dangerous")}
                        >
                          <Ans
                            className={
                              wrongAnswers?.quizTwo?.["खतरनाक / Dangerous"]
                                ? "wrongAns"
                                : ""
                            }
                          >
                            खतरनाक / Dangerous
                          </Ans>
                        </Col>
                      </Row>
                    </QuizOption>
                  </>
                ) : (
                  <>
                    {/* Quiz 1 */}
                    <QuizNum>
                      <h2>1</h2>
                      <img src="/images/number.png" alt="" />
                    </QuizNum>
                    <AboutText>
                      तूफ़ान ड्रिपलाइन कितनी अधिक शक्तिशाली है?
                    </AboutText>
                    <AboutText>
                      How much more powerful is the Toofan Dripline?
                    </AboutText>
                    <QuizOption>
                      <Row>
                        <Col span={24}>
                          <Ans
                            className={
                              wrongAnswers?.quizOne || quizState?.quizOne
                                ? "rightAns"
                                : ""
                            }
                            onClick={() => {
                              handleQuiz("quizOne");
                            }}
                          >
                            40%
                          </Ans>
                        </Col>
                        <Col span={24} onClick={() => quizOne("30%")}>
                          <Ans
                            className={
                              wrongAnswers?.quizOne?.["30%"] ? "wrongAns" : ""
                            }
                          >
                            30%
                          </Ans>
                        </Col>
                        <Col span={24} onClick={() => quizOne("10%")}>
                          <Ans
                            className={
                              wrongAnswers?.quizOne?.["10%"] ? "wrongAns" : ""
                            }
                          >
                            10%
                          </Ans>
                        </Col>
                        <Col span={24} onClick={() => quizOne("2%")}>
                          <Ans
                            className={
                              wrongAnswers?.quizOne?.["2%"] ? "wrongAns" : ""
                            }
                          >
                            2%
                          </Ans>
                        </Col>
                      </Row>
                    </QuizOption>
                  </>
                )}
              </>
            )}
          </>
        )}
      </QuizCard>
    </Wrapper>
  );
};

export default Quiz;
