import React from 'react';
import {
  Items,
  ModalContainer,
  PhotoArea,
  PlayButton,
  UserName,
} from './index.style';
import { Form, Input, InputNumber } from 'antd';
// Actions
import { updateUser, clearUser } from 'actions/userActions';
import { useAppContext } from 'context/GlobalContext';
import useFirebase from 'hooks/useFirebase';

const LoginModal = ({
  visible,
  setVisible,
  showQuizModal,
  showQuizDetailsModal,
}) => {
  const { userState, uniqueID, setLoading, userDispatch } = useAppContext();
  const { pushToFirestore } = useFirebase();

  console.log(visible, ' userState');
  const onFinish = async (values) => {
    if (values?.username) {
      setLoading(true);
      await pushToFirestore(uniqueID, {
        ['Phone Number']: values?.phone,
        ['Username']: values?.username,
      });
      if (visible?.mode === 'half') {
        await showQuizModal({ show: true });
      } else {
        await showQuizDetailsModal({ show: true });
      }
      setLoading(false);
    }
    userDispatch(updateUser({ ...values }));
    setVisible(false);
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <ModalContainer
      open={visible?.state}
      onCancel={() => setVisible(false)}
      footer={false}>
      {visible?.mode === 'full' && (
        <PhotoArea>
          <img src='/images/login.png' alt='' />
        </PhotoArea>
      )}

      <UserName>कृपया अपने डिटेल्स भरें</UserName>
      <Items>Please fill your details</Items>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'>
        <Form.Item
          name='username'
          rules={[
            {
              required: true,
              message: 'Please input your name!',
              maxLength: 15,
            },

            {
              maxLength: 15,
              message: 'Name should be less than 15 character',
            },
            {
              minLength: 4,
              message: 'Name should be than 5 character',
            },
          ]}>
          <Input placeholder='नाम / Name' />
        </Form.Item>
        <Form.Item
          name='phone'
          rules={[
            {
              required: true,
              message: 'Please input your number!',
            },
          ]}>
          <InputNumber placeholder='मोबाइल नंबर / Mobile Number' />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <PlayButton type='primary' htmlType='submit'>
            Submit
          </PlayButton>
        </Form.Item>
      </Form>
    </ModalContainer>
  );
};

export default LoginModal;
