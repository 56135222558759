import logo from "./logo.svg";
import "./App.css";
import "antd/dist/antd.min.css";
import AllRoutes from "./routes";
import { useAppContext } from "context/GlobalContext";
import { CircleLoader } from "react-spinners";
import { Loader } from "common/index.style";

function App() {
  const { loading } = useAppContext();

  return (
    <div className="App">
      {loading && (
        <Loader>
          <CircleLoader color="#36d7b7" size={80} loading={true} />
        </Loader>
      )}
      <AllRoutes />
    </div>
  );
}

export default App;
