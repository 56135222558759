import styled from "styled-components";

export const Wrapper = styled("div")`
  background-image: url("./images/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 1px 0em 3em;
  height: 92vh;
  @media (max-width: 375px) {
    & {
      height: unset !important;
      padding: 1px 0em 4em;
    }
  }
`;
export const QuizCard = styled("div")`
  max-width: 310px;
  margin: 4em auto 2em;
  padding: 0 0.5em 1em;
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
  border: 2px solid #0a7dd6;
  box-shadow: 0px 5px 10px 0px #00000050;
  h2 {
    font-weight: 800;
    color: #353750;
  }
`;

export const Loader = styled.div`
  background-color: black !important;
  opacity: 0.5 !important;
  width: 100vw !important;
  height: 105vh !important;
  position: fixed !important;
  z-index: 90000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;
