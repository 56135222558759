import React from "react";
import {
  ModalBody,
  ModalContainer,
  PhotoArea,
  PlayButton,
  UserName,
} from "./index.style";

const QuizModal = ({ visible, setVisible }) => {
  return (
    <ModalContainer open={visible} onCancel={setVisible} footer={false}>
      <PhotoArea>
        <img src="/images/check-mark.svg" alt="" />
      </PhotoArea>
      <ModalBody>
        <UserName>
          <h1>धन्यवाद</h1> <h2>Thank You</h2>
        </UserName>

        <p>नेटाफिम की टीम जल्द ही जुड़ेगी आपके साथ।</p>
        <p>Netafim team will be connecting with you soon.</p>
      </ModalBody>
    </ModalContainer>
  );
};

export default QuizModal;
