// quizActions.js

export const TOGGLE_QUESTION = "TOGGLE_QUESTION";
export const CLEAR_QUIZ = "CLEAR_QUIZ";

export const toggleQuestion = (questionName) => {
  return {
    type: TOGGLE_QUESTION,
    payload: questionName,
  };
};
export const clearQuiz = () => {
  return {
    type: CLEAR_QUIZ,
  };
};
