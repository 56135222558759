import React, { useEffect, useState } from "react";
import { QuizCard, Wrapper } from "../../common/index.style";
import {
  AboutText,
  Ans,
  Follow,
  QuizNum,
  QuizOption,
  TopHeader,
} from "./index.style";
import { Col, Row } from "antd";
import { UserName } from "../../components/modals/quizModal/index.style";
import { useAppContext } from "context/GlobalContext";
import { getCurrentAnswers, getPercentage } from "./helper";
import useFirebase from "hooks/useFirebase";
import { clearUser } from "actions/userActions";
import { clearQuiz } from "actions/quizActions";

const Result = () => {
  const {
    quizState,
    userState,
    uniqueID,
    userDispatch,
    quizDispatch,
    likeToofan,
    setScore,
    score,
  } = useAppContext();
  const { pushToFirestore } = useFirebase();

  useEffect(() => {
    if (quizState) {
      setScore(getPercentage(getCurrentAnswers(quizState) * 25));
    }
  }, [quizState]);

  useEffect(() => {
    if (score && userState?.username) {
      pushToFirestore(uniqueID, {
        ["Interested in Toofan"]: likeToofan,
        ["Phone Number"]: userState?.phone,
        ["Username"]: userState?.username,
        ["Questions Score"]: score,
      })
        .then((res) => {
          // userDispatch(clearUser());
        })
        .catch(() => {});
    }
  }, [score]);

  return (
    <Wrapper>
      <QuizCard>
        <TopHeader>
          <h1>परिणाम / RESULT</h1>{" "}
        </TopHeader>
        <h2 style={{ fontFamily: "'Roboto', sans-serif" }}>
          कुल स्कोर / Total Score
        </h2>
        <UserName>
          <h1 style={{ marginBottom: "0.5em" }}>{score}/100</h1>
        </UserName>
        <AboutText>
          लकी विजेताओं की घोषणा होगी <br /> <strong>21 नवंबर 2023</strong> को
          हमारे <br /> <strong>Facebook </strong>और<strong> Instagram</strong>{" "}
          पर।
        </AboutText>
        <AboutText>
          The announcement of the lucky winners will be made on <br />
          <strong>November 21, 2023,</strong> on our <br />{" "}
          <strong>Facebook</strong> and <strong>Instagram</strong> pages.
        </AboutText>
        <Follow>
          <p>हमें फ़ॉलो करें / Follow Us</p>
          <div>
            <a href="https://www.facebook.com/netafimindia">
              <img src="/icons/f-icon.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/netafimindia_official/">
              <img src="/icons/insta-icon.svg" alt="" />
            </a>
          </div>
        </Follow>
      </QuizCard>
    </Wrapper>
  );
};

export default Result;
