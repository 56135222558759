import useFirebase from "hooks/useFirebase";
import React from "react";
import styled from "styled-components";

const SettingsWrapper = styled.div`
  text-align: center;
  margin-top: 40%;
`;

export default function Settings() {
  const { downloadCollectionCSV } = useFirebase();
  return (
    <SettingsWrapper>
      <h3>Retrieve the Most Recent Campaign Report 📁</h3>
      <button onClick={downloadCollectionCSV}>Download⤵</button>
    </SettingsWrapper>
  );
}
