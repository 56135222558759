// quizReducer.js
import quizState from "../state/quizState";
const quizReducer = (state = quizState, action) => {
  switch (action.type) {
    case "TOGGLE_QUESTION":
      return {
        ...state,
        [action.payload]: !state?.[action.payload],
      };
    case "CLEAR_QUIZ":
      return {
        ...quizState,
      };
    case "TOGGLE_TOOFAN_INTEREST":
      return {
        ...state,
        isInterestedInToofan: !state.isInterestedInToofan,
      };
    default:
      return state;
  }
};

export default quizReducer;
