import styled from "styled-components";

export const TopHeader = styled("div")`
  background-image: url("./images/top.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 0.5em 1.5em;
  text-align: center;
  height: 70px;
  position: relative;
  top: -17px;
  left: -27px;
  width: 350px;
  h1 {
    color: #ffffff;
  }
`;
export const QuizNum = styled("div")`
  position: relative;
  text-align: center;
  margin-bottom: 1em;
  h2 {
    color: #ffffff !important;
    position: absolute;
    font-family: "Roboto", sans-serif;
    top: 5%;
    left: 50%;
    font-weight: 700;
    font-size: 4em;
    transform: translate(-50%);
  }
`;
export const AboutText = styled("p")`
  font-size: 1.35em;
`;
export const QuizOption = styled("div")`
  padding: 1em;
  .rightAns,
  .wrongAns {
    background: linear-gradient(45deg, #dc1f2c, #de2f2c);
    box-shadow: inset 0px 0px 12px 2px #00000080;
    color: #ffffff;
  }
  .rightAns {
    background: linear-gradient(45deg, #57b476, #14824d) !important;
    box-shadow: inset 0px 0px 12px 2px #00000080;
    color: #ffffff;
  }
  .bgGrayGradiant {
    &:hover {
      background-color: #fff !important;
      color: #000;
    }
  }
`;
export const Ans = styled("span")`
  font-weight: 700 !important;
  max-width: 275px;
  margin: 0.5em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  line-height: 1;
  //& padding: 0.7em 0;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: inset 0px 0px 6px -2px #00000080;
  background: linear-gradient(#ffffff, #ffffff);
  // &:hover {
  //&   background: linear-gradient(45deg, #57b476, #14824d);
  //&   box-shadow: inset 0px 0px 12px 2px #00000080;
  //&   color: #ffffff;
  //& }
`;
