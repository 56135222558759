// userActions.js

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";

export const updateUser = (userData) => {
  return {
    type: UPDATE_USER_INFO,
    payload: userData,
  };
};

export const clearUser = () => {
  return {
    type: CLEAR_USER_INFO,
  };
};