import React, { useEffect, useRef } from "react";
import { ModalContainer, VideoContainer } from "./index.style";
import YouTube from "react-youtube";

const VideoModal = ({ visible, setVisible }) => {
  const embedUrl = `https://youtu.be/FHQdNf3vTIs`;
  const ref = useRef();
  const opts = {
    playerVars: {
      autoplay: 0, // 1 for autoplay
    },
  };

  const handleCancel = () => {
    ref?.current?.resetPlayer();
    setVisible(false);
  };

  console.log(ref, "ref...");

  return (
    <ModalContainer
      open={visible}
      onCancel={handleCancel}
      footer={false}
      centered
    >
      <VideoContainer>
        <div className="video-container">
          <YouTube ref={ref} videoId="FHQdNf3vTIs" opts={opts} />
        </div>
      </VideoContainer>
    </ModalContainer>
  );
};

export default VideoModal;
