import styled from "styled-components";

export const TopHeader = styled("div")`
  background-image: url("./images/top.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 0.5em 1.5em;
  text-align: center;
  height: 60px;
  position: relative;
  top: -17px;
  left: -27px;
  width: 350px;
  h1 {
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
  }
`;
export const QuizNum = styled("div")`
  position: relative;
  text-align: center;
  margin-bottom: 1em;
  h2 {
    color: #ffffff;
    position: absolute;
    top: 1%;
    left: 50%;
    font-size: 4em;
    transform: translate(-50%);
  }
`;
export const AboutText = styled("p")`
  font-weight: 500;
  font-size: 1.2em;
  strong {
    font-weight: 700;
  }
`;
export const QuizOption = styled("div")`
  padding: 1em;
`;
export const Ans = styled("span")`
  font-weight: 900;
  max-width: 250px;
  margin: 0.5em auto 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  padding: 0.7em 0;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: inset 0px 0px 6px -2px #00000050;
`;

export const Follow = styled("div")`
  border-top: 2px solid #0a7dd6;
  padding: 1em;
  p {
    font-weight: 500;
    margin-bottom: 0;
  }
  a {
    display: inline-block;
    margin: 0.5em;
  }
`;
