import { Link } from "react-router-dom";
import styled from "styled-components";
export const Wrapper = styled("div")`
  background: #ffffff;
  padding: 1px 0em 3em;
`;

export const TopSection = styled("div")`
  padding: 1em 0em 0;
  text-align: center;
  margin: 0em auto 1.3em;
  max-width: 330px;
  img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0px 3px 12px 0px #000000;
  }
`;
export const QuizSection = styled("div")`
  // max-width: 332px;
  margin: 1em auto;
  padding: 1em;
  background-color: #353750;
  border-radius: 0px;
  border-top: 3px solid #ff8a00;
  border-bottom: 3px solid #ff8a00;
  box-shadow: 0px 3px 12px 0px #000000;
  text-align: center;
  p {
    margin-bottom: 0;
    font-weight: 700 !important;
    font-size: 1.3em;
    color: #ffffff !important;
    text-align: left;
  }
  span {
    color: #ffffff !important;
  }
  img {
    border-radius: 50%;
  }
`;
export const PlayContainer = styled(Link)`
  color: #000000;
  font-weight: 900;
  max-width: 335px;
  margin: 0 auto;
  display: block;
  img {
    max-width: 100%;
  }
`;

export const VideoSection = styled("div")`
  background-image: url(/images/n-video.png);
  background-size: 101% 100%;
  background-repeat: no-repeat;
  max-width: 332px;
  height: 180px;
  margin: 1.8em auto 0.5em;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  img {
    position: absolute;
    opacity: 0.8;
    width: 40px;
    height: 40px;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
    cursor: pointer;
    border-radius: 50%;
  }
`;

export const VideoButton = styled("span")`
  background: linear-gradient(0deg, #005eb8, #005eb8, #005eb880);
  font-weight: 900;
  width: 290px;
  border: 1px solid #005eb860;
  margin: 2em auto 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  padding: 0.5em 0;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 700;
  color: #ffffff;
`;
