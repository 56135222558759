import { Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    background: linear-gradient(#ffffff, #c3dff5);
    border-radius: 10px;
    border: 2px solid #005eb8;
    margin-bottom: 2em;
    .ant-modal-close {
      width: 15px;
      height: 15px;
      top: 10px;
      right: 15px;
      border-radius: 50%;
      overflow: hidden;
      .ant-modal-close-x {
        text-align: center;
        width: 15px;
        height: 15px;
        line-height: 10px;
        background: #005eb8;
        svg {
          color: #ffffff;
          font-size: 0.6em;
        }
      }
    }
    .ant-modal-body {
      border-radius: 10px;
      background: linear-gradient(#ffffff, #ffffff, #ffffff, #c3dff5);
      padding: 1em 1.5em;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
    }
  }

  .ant-modal-header {
    display: none;
  }
  @media (max-width: 400px) {
    & {
      .ant-modal-content {
        max-width: 300px;
        margin: 0 auto 4em;
      }
    }
  }
  @media (max-width: 385px) {
    & {
      .ant-modal-content {
        max-width: 300px;
        margin-top: -31px;
      }
    }
  }
`;
export const ListContainer = styled("div")``;
export const PhotoArea = styled("div")`
  max-width: 312px;
  margin: auto;
  margin-top: 1em;
  border-radius: 10px;
  img {
    width: 100%;
  }
  // @media (max-width: 400px) {
  //   & {
  //   }
  // }
`;
export const UserName = styled("h1")`
  text-align: center;
  font-weight: 900;
  color: #353750;
  font-size: 1.6em;
  margin-top: 0.5em;
`;
export const ListItems = styled("div")`
  font-size: 0.7em;
  font-weight: 600;
`;
export const Items = styled("p")`
  margin-bottom: 0em;
  a {
    color: #000000;
    display: inline-block;
    margin: 0 3px;
    margin-bottom: -2px;
  }
`;
export const PlayButton = styled(Link)`
  background: linear-gradient(0deg, #005eb8, #005eb8, #005eb880);
  font-weight: 700;
  width: 170px;
  border: 1px solid #005eb860;
  margin: 1em auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  padding: 0.5em;
  border-radius: 50px;
  font-weight: 700;
  color: #ffffff;
  img {
    width: 25px;
    margin-right: 0.5em;
  }
`;
