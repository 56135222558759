import React, { useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import {
  Items,
  ListContainer,
  ListItems,
  ModalContainer,
  PhotoArea,
  PlayButton,
  UserName,
} from "./index.style";
import { Col, Row } from "antd";
import TimeDetailsModal from "./details/TimeDetails";

const QuizDetailsModal = ({ visible, setVisible }) => {
  const [showInfo, setShowInfo] = useState(false);
  const handleCancel = () => {
    setShowInfo(false);
  };

  const userDetails = [
    {
      key: "1",
      about1: `खेलिए तूफानी, जीतिए तूफानी" प्रतियोगिता एक तूफान ड्राइपलाइन की विशेषताओं पर आधारित क्विज़ है। आपको प्रदान किए गए प्रश्नों के उत्तर देने की आवश्यकता होगी।`,
      about2: `The Kheliye Toofani Jeetiye Toofani contest is a quiz on the attributes of the Toofan dripline. You will be required to provide answers to the provided questions`,
    },
    {
      key: "2",
      about1: `अपने उत्तर भरने के बाद, कृपया प्रतियोगिता में प्रवेश करने के लिए सबमिट बटन पर क्लिक करें।`,
      about2: `Once you have filled in your answers, please click submit button to enter the contest`,
    },
    {
      key: "3",
      about1: `सभी प्रश्नों के सही उत्तरों वाले प्रतिस्पर्धी को भागीदारी के लिए चयनित किया जाएगा।`,
      about2: `The participants with the correct answers to all the questions will be shortlisted for a lucky draw`,
    },
    {
      key: "4",
      about1: `भाग्य विधा के माध्यम से 10 विजेताओं का चयन होगा।`,
      about2: `There will be selection of 10 winners through the lucky draw activity.`,
    },
    {
      key: "5",
      about1: `परिणाम 21 नवम्बर 2023 को हमारे सोशल मीडिया चैनल्स के माध्यम से घोषित किए जाएंगे।`,
      about2: `Results would be announced on 21st November 2023 through our social media channels`,
    },
    {
      key: "6",
      about1: (
        <p style={{ marginBottom: "initial" }}>
          कृपया यहाँ क्लिक करें
          <a href="#" onClick={() => setShowInfo(true)}>
            <BsInfoCircleFill />
          </a>
          खेलिए तूफानी जीतिए तूफानी प्रतियोगिता के नियम और शर्तों के बारे में
          जानने के लिए।
        </p>
      ),
      about2: (
        <p style={{ marginBottom: "initial" }}>
          {" "}
          Please click here{" "}
          <a href="#" onClick={() => setShowInfo(true)}>
            <BsInfoCircleFill />
          </a>{" "}
          to know about the terms and conditions of the Kheliye Toofani Jeetiye
          Toofani contest
        </p>
      ),
    },
    {
      key: "7",
      about1: `"शुरू" बटन पर क्लिक करके आप प्रतियोगिता की शर्तों और नियमों से सहमत होते हैं।`,
      about2: `By clicking on the start button you agree to the terms and conditions of the contest.`,
    },
  ];

  return (
    <>
      <TimeDetailsModal show={showInfo} setShow={handleCancel} />
      <ModalContainer open={visible} onCancel={setVisible} footer={false}>
        <ListContainer>
          <PhotoArea>
            <img src="/images/modal-img.png" alt="" />
          </PhotoArea>
          <UserName>प्रतियोगिता के नियम</UserName>
          <ListItems>
            {userDetails.map((items, i) => (
              <div style={{ marginBottom: "1.5em" }}>
                <Row>
                  <Col span={1}>
                    <span>{items.key}.</span>
                  </Col>
                  <Col span={23}>
                    <Items>{items.about1}</Items>
                  </Col>
                </Row>
                <Row>
                  <Col span={1}>
                    <span>{items.key}</span>
                  </Col>
                  <Col span={23}>
                    <Items>{items.about2}</Items>
                  </Col>
                </Row>
              </div>
            ))}
          </ListItems>
          <PlayButton to="/quiz">
            <img src="/images/play.svg" alt="" />
            शुरु करें / START
          </PlayButton>
        </ListContainer>
      </ModalContainer>
    </>
  );
};

export default QuizDetailsModal;
