import React from "react";
import { Items, ModalContainer } from "../index.style";

const TimeDetailsModal = ({ show, setShow }) => {
  return (
    <ModalContainer open={show} onCancel={setShow} footer={false}>
      <Items style={{ fontSize: "0.9em", marginTop: "1em" }}>
        {`
        TOOFAN CONTEST (the "Quiz") is designed to test the knowledge of the
        participants (the "Participant(s)") regarding TOOFAN DRIP LINE. The Quiz
        competition will be held in accordance with Rules (the "Rules") listed
        hereinafter: REGISTRATION FOR THE QUIZ CONSTITUTES YOUR ACCEPTANCE OF
        THESE RULES: ORGANISER: The Quiz is organized by NETAFIM IRRIGATION
        INDIA PRIVATE LIMITED (hereinafter referred to as "NETAFIM").
        ELIGIBILITY FOR PARTICIPATING IN THIS QUIZ: The Quiz is open to people
        (the “Participant(s)”) anywhere in India who have completed the age of
        18 years of age as on DATE OF ANNOUNCEMENT OF QUIZ i.e26th Oct 2023
        BINDING: In order to enter the Quiz, Participant must accept the Rules.
        The Rules include the terms and conditions on the Quiz page. The
        Participant should read carefully prior to entry to the Quiz to ensure
        that he/she understands and agrees, because these Rules form a legally
        binding to the Participants with respect to this Quiz. QUIZ COMPETITION:
        The Quiz will consist of 5 questions for the Participants to answer.
        Quiz will be open from 26th Oct 2023 to 15th November 2023by NETAFIM
        (“Quiz Period”). PRIZE: Prize (the “Prize”) details for Quiz will be
        announced separately. All prizes are non-transferable. Cash will not be
        awarded in lieu of the Prize. All prizes constituting electronic
        products may or may not come with warranty. NETAFIM shall not be
        responsible for providing any warranties or for any quality of such
        products. Winners of such products are advised to check directly with
        the product manufacturers to verify the validity of the warranty -
        please see your product box and literature for details. SELECTION OF
        WINNERS: In order to be eligible for prize, Participant must register
        for the Quiz. The winners will be chosen among the Participants based on
        whoever answers the highest number of questions correctly in the
        shortest span of time. Winners will be selected by NETAFIM’s internal
        panel of judges from all eligible entries received during the Quiz
        Period. Decision of the NETAFIM panel will be final and binding in all
        respects. This is the Quiz of skill. Participants odds of winning depend
        on how well Participants entry compares with the other submitted in the
        Quiz pursuant to the judging criteria. All winners will be notified by
        SMS and communicated by NETAFIM and Proper ID proof is required in order
        to claim the prize. Prize must be claimed by within fourteen (14)
        business days after receipt of SMS from NETAFIM. CONDITIONS FOR
        DISTRIBUTION OF PRIZES TO WINNERS: If any winner is not able, for
        whatever reason, to accept his/ her prize then NETAFIM reserves the
        right to award the Prize to another winner. Any Prize(s) that remain
        unclaimed at the end of thirty (30) days from the announcement of the
        final results will be cancelled. NETAFIM reserves the right to award
        this Prize to the next winner or even revoke that Prize. LIMITATIONS OF
        LIABILITY: In no event shall NETAFIM be liable in any manner whatsoever
        towards the Participants for any indirect, special, incidental, or
        consequential damages (including lost profits) directly or indirectly
        relating to Annotation arising out of the Quiz, regardless of the form
        of action, whether in contract, tort, strict liability, or otherwise,
        and whether or not such damages were foreseen or unforeseen, even if
        NETAFIM has been advised of the possibility thereof. Notwithstanding
        what is stated hereinabove, it is hereby specifically agreed that
        NETAFIM shall not be responsible to the Participants for any debt, loss
        or liability (including liability for death, bodily injury, property
        damage arising out of the Quiz). OTHER CONDITIONS: NETAFIM reserves the
        right to disqualify any Participant from the Quiz if, in NETAFIM 's sole
        discretion, it reasonably believes that the Participant has attempted to
        undermine the legitimate operation of the Quiz by cheating, deception,
        or other unfair playing practices or annoys, abuses, threatens or
        harasses any other Participants, NETAFIM, or the judges. PRIVACY:
        Participants agree that personal data submitted with an entry, including
        name and phone number, may be collected, processed, stored and otherwise
        used by NETAFIM, its affiliates and its officer for the purposes of
        conducting and administering the Quiz. By entering the Quiz,
        Participants agree to the transmission, processing, disclosing and
        storage of the personal data by NETAFIM, its affiliates and its
        officers. Participants consent to share his/her Personal data i.e. Name,
        Phone number on Quiz portal and NETAFIM’s website/social media platforms
        of NETAFIM for announcement of the winners, if Participants are chosen
        as winner(s). ELIMINATION: Any false information provided within the
        context of the Quiz by Participant concerning identity, telephone
        number, ownership of right or non- compliance with these Rules or the
        like may result in the immediate elimination of the Participant from the
        Quiz. INTERNET AND DISCLAIMER: NETAFIM shall not be responsible for any
        malfunction of the entire Quiz site or other telecommunication
        transmission malfunctions, network connectivity problems, hardware or
        software failures of any kind. RIGHT TO CANCEL, MODIFY OR DISQUALIFY: If
        for any reason the Quiz is not capable of running as planned, including
        infection by virus, bugs, tampering, unauthorized intervention, fraud,
        technical failures, or any other causes which corrupt or affect the
        administration, security, fairness, integrity, or proper conduct of the
        Quiz, NETAFIM reserves the right at its sole discretion to cancel,
        terminate, modify or suspend the Quiz. NETAFIM further reserves the
        right to disqualify any Participants who tampers with the submission
        process or any other part of the Quiz or Quiz site. GOVERNING LAWS AND
        RECOURSE TO JUDICIAL PROCEDURES: This Quiz is governed by the laws of
        India, and only the courts of Vadodara will have exclusive jurisdiction
        to try any dispute arising from this Quiz. SELECTION OF WINNER: The
        Prize distribution time and mode of distribution of the Prize shall be
        at the sole discretion of NETAFIM. The winners will be selected through
        a lucky draw from all the Participants who have submitted the correct
        answers. The decision of NETAFIM on all matters relating to or in
        connection with this Quiz (including selection of the winners) will be
        final and binding on all parties concerned.`}
      </Items>
    </ModalContainer>
  );
};

export default TimeDetailsModal;
