import React from "react";
import { FooterContainer } from "./index.style";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import PDF from "../../assets/Pdf/Toofan_Leaflet_Microsite_281023.pdf";

const Footer = () => {
  return (
    <FooterContainer>
      <Row>
        <Col span={8}>
          <Link to="/">
            <img src={`./images/home.svg`} alt="" />
            <div>Home</div>
          </Link>
        </Col>
        <Col span={8}>
          <a href={PDF} download>
            <img src={`./images/note-icon.svg`} alt="" />
            <div>Brochure</div>
          </a>
        </Col>
        <Col span={8}>
          <a href="tel:18001032000">
            <img src={`./images/call.svg`} alt="" />
            <div>Contact Us</div>
          </a>
        </Col>
      </Row>
    </FooterContainer>
  );
};

export default Footer;
