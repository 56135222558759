// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCe3d9Y9qZoXzy7yyZ47aMwH02BdR3a0Y8",
  authDomain: "netafim-microsite.firebaseapp.com",
  projectId: "netafim-microsite",
  storageBucket: "netafim-microsite.appspot.com",
  messagingSenderId: "319657914146",
  appId: "1:319657914146:web:55a95bd8b980243bb4ff7b",
  measurementId: "G-NETBCGMYTF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const fireStorage = getStorage(app);
export const db = getFirestore(app);
