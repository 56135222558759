import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Quiz from '../screens/quiz';
import Home from '../screens/home';
import UserLayout from '../layout/userLayout';
import Result from '../screens/result';
import Settings from '../screens/settings';

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<UserLayout />}>
          <Route path='/' element={<Home />} />
          <Route path='/quiz' element={<Quiz />} />
          <Route path='/result' element={<Result />} />
          <Route path='/settingss' element={<Settings />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
