import React, { createContext, useReducer, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Reducers
import userReducer from "reducers/userReducer";
import quizReducer from "reducers/quizReducer";

// Initial States
import initialQuizState from "state/quizState";
import initialUserState from "state/userState";

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [quizState, quizDispatch] = useReducer(quizReducer, initialQuizState);
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);
  const [likeToofan, setLikeToofan] = useState("No");
  const [score, setScore] = useState(null);
  const [uniqueID, setUniqueID] = useState(uuidv4());
  const [loading, setLoading] = useState(false);

  // You can add any other context-specific state or logic here

  return (
    <AppContext.Provider
      value={{
        userState,
        userDispatch,
        quizState,
        quizDispatch,
        likeToofan,
        setLikeToofan,
        setScore,
        score,
        uniqueID,
        loading,
        setLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
};

export { AppContext, AppContextProvider, useAppContext };
