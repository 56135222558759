import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import {
  PlayContainer,
  QuizSection,
  TopSection,
  VideoButton,
  VideoSection,
  Wrapper,
} from "./index.style";
import VideoModal from "../../components/modals/video";
import QuizModal from "../../components/modals/quizModal";
import QuizDetailsModal from "../../components/modals/quizDetails";
import LoginModal from "../../components/modals/loginFormModal";
import { useAppContext } from "context/GlobalContext";
import useFirebase from "hooks/useFirebase";
import QuizModalEnd from "components/modals/quizModal/quizEnd";

const Home = () => {
  const { setLoading, likeToofan, setLikeToofan, uniqueID, userState } =
    useAppContext();
  const { pushToFirestore } = useFirebase();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const [quizSubmit, setQuizSubmit] = useState(
    likeToofan == "No" ? false : true
  );
  const [loginModalOpen, setLoginModalOpen] = useState({
    state: false,
    mode: "full",
  });
  const [quizDetailsModalOpen, setQuizDetailsModalOpen] = useState(false);

  const showVideoModal = () => {
    setIsVideoModalOpen(true);
  };
  const showQuizModal = async ({ show = false }) => {
    if ((userState?.username || show) && !quizSubmit) {
      setLoading(true);
      await pushToFirestore(uniqueID, {
        ...userState,
        ["Interested in Toofan"]: "Yes",
        ["Phone Number"]: userState?.phone,
        ["Username"]: userState?.username,
      });
      setLoading(false);
      setLikeToofan("Yes");
      setQuizModalOpen(true);
      setQuizSubmit(true);
    } else if (!quizSubmit) {
      setLoginModalOpen((prev) => ({ ...prev, state: true, mode: "half" }));
    }
  };
  const showLoginModal = () => {
    setLoginModalOpen(true);
  };
  const showQuizDetailsModal = ({ show = false }) => {
    if (userState?.username || show) {
      setQuizDetailsModalOpen(() => true);
    } else {
      setLoginModalOpen((prev) => ({ ...prev, state: true, mode: "full" }));
    }
  };
  const handleCancel = () => {
    setIsVideoModalOpen(false);
    setQuizModalOpen(false);
    setLoginModalOpen((prev) => ({ ...prev, state: false }));
  };

  const handleCancelQuizModal = () => {
    setQuizDetailsModalOpen(false);
  };

  useEffect(() => {
    // Call the function to open the quiz modal when the component mounts
    showQuizModal({ show: false });
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  useEffect(() => {
    // Check if the user is not logged in
    if (!userState?.username) {
      // Call the function to open the login modal when the component mounts
      showLoginModal();
    } else {
      // Call the function to open the quiz modal if the user is logged in
      showQuizModal({ show: false });
    }
  }, [userState]); // Include userState in the dependency array to re-run the effect when userState changes
  return (
    <>
      <VideoModal visible={isVideoModalOpen} setVisible={handleCancel} />
      <QuizModal visible={quizModalOpen} setVisible={handleCancel} />
      <QuizModalEnd visible={quizModalOpen} setVisible={handleCancel} />
      <LoginModal visible={loginModalOpen} setVisible={handleCancel} />
      {/* <button onClick={downloadCollectionCSV}>DOWNLOADGU</button> */}
      <LoginModal
        visible={loginModalOpen}
        setVisible={handleCancel}
        showQuizModal={showQuizModal}
        showQuizDetailsModal={showQuizDetailsModal}
      />
      <QuizDetailsModal
        visible={quizDetailsModalOpen}
        setVisible={handleCancelQuizModal}
      />
      <Wrapper>
        <TopSection>
          <img src="/images/top-ban.jpg" alt="" />
        </TopSection>
        <QuizSection>
          <div style={{ maxWidth: "332px", margin: "0em auto" }}>
            <Row justify="space-between" align="middle">
              <Col span={16}>
                <p>
                  खेतों में तूफ़ान ड्रिपलाइन <br /> लगाने के लिए बटन दबाएं
                </p>
                <p style={{ fontWeight: "400" }}>
                  Interested in Buying Toofan?
                </p>
              </Col>
              <Col span={6} onClick={showQuizModal}>
                <img
                  style={quizSubmit ? { opacity: "0.5" } : { opacity: "1" }}
                  src="/images/push-btn.svg"
                  alt=""
                />
                <span onClick={showQuizModal}>Click Here</span>
              </Col>
            </Row>
          </div>
        </QuizSection>
        {/* <PlayContainer to='#' onClick={showQuizDetailsModal}> */}
        <PlayContainer to="#" onClick={() => setQuizModalOpen(true)}>
          <img src="/images/video-banner.png" alt="" />
        </PlayContainer>
        <VideoSection onClick={showVideoModal}>
          <img src="/images/play.svg" alt="" />
        </VideoSection>

        <VideoButton onClick={showVideoModal}>
          तूफ़ान लॉन्च वीडियो / Toofan Launch Video
        </VideoButton>
      </Wrapper>
    </>
  );
};

export default Home;
