import React from 'react';
import {
  ModalBody,
  ModalContainer,
  PhotoArea,
  PlayButton,
  UserName,
} from './quizEnd.style';

const QuizModalEnd = ({ visible, setVisible }) => {
  return (
    <ModalContainer
      // onCancel={() => {}}
      onCancel={setVisible}
      open={visible}
      maskClosable={true}
      footer={false}
      closable={true}>
      <PhotoArea>
        <img src='/images/check-mark.svg' alt='' />
      </PhotoArea>
      <ModalBody>
        <UserName>
          <h1>धन्यवाद</h1> <h2>Thank You</h2>
        </UserName>

        <p>
          प्रतियोगिता समाप्त हो गई है. विजेताओं की घोषणा जल्द ही की जाएगी, बने
          रहें।
        </p>
        <p>Contest has ended. Winners will be announced soon, Stay tuned.</p>
      </ModalBody>
    </ModalContainer>
  );
};

export default QuizModalEnd;
