import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    border: 2px solid #005eb8;
    border-radius: 15px;
    max-width: 300px;
    margin: auto;
    overflow: hidden;
    padding: 0;
    background: #005eb8;
    /* .ant-modal-close {
      width: 15px;
      height: 15px;
      top: 10px;
      right: 15px;
      border-radius: 50%;
      overflow: hidden;
      .ant-modal-close-x {
        text-align: center;
        width: 15px;
        height: 15px;
        line-height: 10px;
        background: #ffffff;
        svg {
          color: #005eb8;
          font-size: 0.6em;
        }
      }
    } */
    .ant-modal-body {
      // border-radius: 10px;
      background: #005eb8;
      padding: 0em;
      line-height: 1.5715;
      word-wrap: break-word;
    }
  }

  .ant-modal-header {
    display: none;
  }
  p {
    text-align: center;
    color: #000000;
  }
`;
export const PhotoArea = styled('div')`
  background: #005eb8;
  text-align: center;
  padding: 1em 2em;
  border-radius: 0;
  img {
    width: 80px;
  }
`;
export const ModalBody = styled('div')`
  background: #ffffff;
  text-align: center;
  padding: 1em 1.4em;
  margin: auto;
  p {
    font-weight: 400;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
  }
`;
export const UserName = styled('div')`
  text-align: center;
  margin-bottom: 1.5em;
  border-bottom: 2px solid #0a7dd6;
  h1 {
    font-weight: 700;
    font-size: 5em;
    color: #0a7dd6;
    margin-bottom: 0em;
    line-height: 1;
  }
  h2 {
    font-weight: 700;
    font-size: 3.5em;
    color: #353750;
  }
`;
// export const Items = styled("p")`
//   margin-bottom: 1em;
//   font-weight: 600;
// `;
// export const PlayButton = styled(Link)`
//   background: linear-gradient(0deg, #005eb8, #005eb8, #005eb850);
//   font-weight: 900;
//   width: 130px;
//   border: 1px solid #005eb8;
//   margin: 1em auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 0.9em;
//   padding: 0.2em 0;
//   border-radius: 50px;
//   font-weight: 700;
//   color: #ffffff;
//   img {
//     width: 30px;
//     margin-right: 0.5em;
//   }
// `;
