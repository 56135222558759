import {
  addDoc,
  collection,
  Firestore,
  doc,
  setDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase/firebase"; // Adjust the import path as needed
import FileSaver from "file-saver";

export default function useFirebase() {
  const pushToFirestore = async (customId, data, setLoading = () => {}) => {
    try {
      setLoading(true);

      const combinedCollectionRef = collection(db, "combinedData");

      // Create a reference to a specific document using the custom ID
      const customDocRef = doc(combinedCollectionRef, customId);

      // Set the data for the specified document
      await setDoc(customDocRef, {
        ...data,
        ["Time Stamp"]: new Date().toDateString(),
      });

      console.log("Data added with custom ID: ", customId);

      setLoading(false);
    } catch (error) {
      console.group("Error");
      console.error(error);
      console.groupEnd();
      setLoading(false);
    }
  };

  const downloadCollectionCSV = async () => {
    try {
      const collectionRef = collection(db, "combinedData"); // Replace 'yourCollection' with your collection name
      const querySnapshot = await getDocs(collectionRef);

      const data = [];

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        data.push(docData);
      });

      if (data.length === 0) {
        alert("The collection is empty.");
        return;
      }

      const csvData = convertToCSV(data);

      const blob = new Blob([csvData], { type: "text/csv" });

      FileSaver.saveAs(blob, "collection.csv");
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };
  const getHeaders = () => [
    "Interested in Toofan",
    "Phone Number",
    "Questions Score",
    "Time Stamp",
    "Username",
    "username",
  ];

  const convertToCSV = (data) => {
    const header = getHeaders();
    const csv = [header.join(",")];
    data.forEach((item) => {
      const row = header.map((fieldName) => item[fieldName]);
      csv.push(row.join(","));
    });

    return csv.join("\n");
  };

  return {
    pushToFirestore,
    downloadCollectionCSV,
  };
}
